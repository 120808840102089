<template>
  <div class="modal" :class="{ 'is-active': active }">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ titleModal }}</p>
        <span class="material-icons close_modal_icon" @click="$emit('close-modal')">close</span>
      </header>
      <section class="modal-card-body p-6">
        <div class="columns is-multiline">
          <div class="column is-12">
            <div class="field control">
              <label class="label">Pregunta</label>
              <input
                class="input"
                type="text"
                placeholder="Pregunta"
                v-model="localQuestion"
                name="localQuestion"
                v-validate="'required'"
                data-vv-as="de la pregunta"
              >
              <span v-show="vverrors.has('localQuestion')" class="help is-danger">{{ vverrors.first('localQuestion') }}</span>
            </div>
          </div>
          <div class="column is-12">
            <div class="field control">
              <label class="label">Respuesta</label>
              <vue-editor
                :editor-toolbar="customToolbar"
                v-model="localAnswer"
                name="localAnswer"
                v-validate="'required'"
                data-vv-as="de la respuesta"
              />
              <span v-show="vverrors.has('localAnswer')" class="help is-danger">{{ vverrors.first('localAnswer') }}</span>
            </div>
          </div>
          <div class="column is-12">
          <div class="is-flex is-align-items-center">
            <label class="label mr-5 mb-0">Estatus</label>
            <span>
              <input id="switchStatus" type="checkbox" name="switchStatusStatus" class="switch is-rounded is-small" v-model="localStatus" :checked="localStatus ? 'checked' : ''">
              <label for="switchStatus"></label>
            </span>
          </div>
        </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button
          class="button button_micro_dark_outlined"
          :disabled="loading"
          @click="$emit('close-modal')"
        >
          CANCELAR
        </button>
        <button
          class="button button_micro_dark"
          @click="actionModal()"
          :class="{ 'is-loading' : loading }"
          :disabled="loading"
        >
          {{ typeModal === 'add' ? 'AGREGAR' : 'ACTUALIZAR' }}
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalFaq',
  data () {
    return {
      localStatus: this.status,
      localQuestion: this.question,
      localAnswer: this.answer,
      customToolbar: [
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
        ['blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
        ['clean'] // remove formatting button
      ]
    }
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    titleModal: {
      type: String,
      default: 'Pregunta'
    },
    idUpdate: {
      type: Number,
      default: null
    },
    question: {
      type: String,
      default: null
    },
    answer: {
      type: String,
      default: null
    },
    status: {
      type: Boolean,
      default: true
    },
    typeModal: {
      type: String,
      default: 'add'
    }
  },
  methods: {
    async actionModal () {
      if (await this.$validator.validateAll()) {
        const data = {
          faqId: this.idUpdate || null,
          question: this.localQuestion,
          answer: this.localAnswer,
          active: this.localStatus
        }
        this.$emit('set-data-action', data)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .modal-card {
    border-radius: 18px;
  }
  .modal-card-head, .modal-card-foot {
    background-color: white;
  }
  .modal-card-foot {
    border-top: none;
    justify-content: flex-end;
  }

  .button {
    font-size: 14px;
    width: 30%;
  }
</style>
